<template>
  <div class="purchase-confirmation">
    <div class="container-xl">
      <div class="card mb-3">
        <div v-if="!confirmation.uuid" class="text-center p-5"><i>Загрузка...</i></div>
        <div v-if="confirmation.uuid" class="card-body">
          <div class="h6">№ {{ confirmation.agreementNumber }}</div>
          <iframe :src="confirmation.agreementUrl"></iframe>
          <form @input="validateForm" @change="validateForm" @submit="sell">
            <div class="row flex-column-reverse flex-lg-row mt-4">
              <div class="col-12 col-lg-8">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input v-model="agreement2" type="checkbox" class="custom-control-input" id="agreement2" required>
                    <label class="custom-control-label" for="agreement2"></label>
                    <public-link to="/page/politika-vzyskaniia-prosrochennoi-zadolzhennosti"
                                 target="_blank"
                    >Согласен с политикой взыскания просроченной задолженности</public-link>
                  </div>
                  <input-errors :errors="errors.agreement2"/>
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input v-model="agreement3" type="checkbox" class="custom-control-input" id="agreement3" required>
                    <label class="custom-control-label" for="agreement3"></label>
                    <public-link to="/page/pravila-vtorichnogo-rynka-investitsionnoi-platformy-lendly"
                                 target="_blank"
                    >Согласен с правилами вторичного рынка</public-link>
                  </div>
                  <input-errors :errors="errors.agreement3"/>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <a :href="confirmation.agreementUrl" target="_blank">
                  {{ documentName }}
                </a>
                <a v-if="sellerDocumentName && confirmation.sellerAgreementUrl"
                   :href="confirmation.sellerAgreementUrl"
                   target="_blank"
                   class="d-block mt-1"
                >
                  {{ resolveDocumentNameSuffix(sellerDocumentName) }}
                </a>
                <div v-if="isAfterUpdate()">
                  <a href="http://cdn.lendly.ru/loan_agreement.docx">Шаблон договора управления залогом</a>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <div class="mb-3">
                  <span class="badge badge-secondary">1</span>
                </div>
                <button @click="sendCode" class="btn btn-info" type="button">
                  Отправить SMS с кодом подтверждения
                </button>
                <div v-if="confirmation.phone" class="text-muted mt-2">
                  На номер: +{{ confirmation.phone.countryCode }} {{ confirmation.phone.number }}
                </div>
              </div>
              <div class="col-12 col-md-3 mb-4 mb-md-0">
                <div class="mb-3">
                  <span class="badge badge-secondary">2</span>
                </div>
                <input v-model="smsCode" ref="smsInput" class="form-control form-control-lg" maxlength="6" placeholder="Код из SMS" required>
                <input-errors :errors="errors.smsCode"/>
              </div>
              <div class="col-12 col-md-3">
                <div class="mb-3">
                  <span class="badge badge-secondary">3</span>
                </div>
                <button type="submit" class="btn btn-lg btn-success">
                  Подтвердить
                </button>
              </div>
            </div>
            <div class="mt-4">
              Подписание настоящего агентского распоряжения осуществляется Принципалом и фиксируется в электронной форме с использованием
              информационных технологий и технических средств Площадки.
            </div>
          </form>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { InputErrors, Processing, PublicLink } from "@/components/common";
import { aggregateValidationErrors } from "@/utils";
import { ValidationError } from "@/utils/api-client";
import { createOfferPurchaseManager } from "@/utils/di";
import { DashboardEventType } from "../../../enums/DashboardEventType";
import moment from "moment";

export default {
  name: "PurchaseConfirmation",
  props: {
    documentName: {
      type: String,
      default() {
        return "Агентское распоряжение на покупку";
      }
    },
    sellerDocumentName: null,
  },
  components: {
    InputErrors,
    Processing,
    PublicLink
  },
  data() {
    return {
      confirmation: {},
      agreement2: false,
      agreement3: false,
      smsCode: null,
      errors: {},
      processing: false
    };
  },
  async mounted() {
    const manager = await createOfferPurchaseManager();
    this.confirmation = await manager.getConfirmation(this.$route.params.uuid, this.$route.params.confirmationUuid);
  },
  methods: {
    isAfterUpdate(){
      return moment(this.confirmation.projectDate).isAfter("2022-04-01T01:14:00Z");
    },
    resolveDocumentNameSuffix(prefix) {
      let suffix = "";
      if (this.isAfterUpdate()){
        suffix = " и оферта ООО Лендли о заключении агентского договора";
      }
      return `${prefix}${suffix} (скачать PDF)`
    },
    validateForm() {
      const errors = {};
      if (!this.agreement2) {
        errors.agreement2 = ['Пожалуйста, отметьте это поле'];
      }
      if (!this.agreement3) {
        errors.agreement3 = ['Пожалуйста, отметьте это поле'];
      }
      if (!this.smsCode) {
        errors.smsCode = ['Пожалуйста, введите код из SMS'];
      }
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    async sendCode() {
      this.processing = true;
      try {
        const manager = await createOfferPurchaseManager();

        await manager.sendConfirmationCode(this.confirmation.offerUuid, this.confirmation.uuid);
      } finally {
        this.processing = false;
        this.$refs.smsInput.focus();
      }
    },
    async sell(e) {
      e.preventDefault();
      if (this.validateForm()) {
        this.processing = true;
        try {
          const manager = await createOfferPurchaseManager();
          await manager.create(this.confirmation.offerUuid, this.confirmation.uuid, this.smsCode);
          this.$emit("dashboardEvent", DashboardEventType.Purchased);
          await this.$router.push({ name: "myInvestments" });
        } catch (err) {
          if (err instanceof ValidationError) {
            this.$notifications.addAlert(err.message)
            this.errors = aggregateValidationErrors(err);
          } else {
            throw err;
          }
        } finally {
          this.processing = false;
        }
      }
    }
  }
}
</script>

<style lang="scss">
.purchase-confirmation {
  iframe {
    width: 100%;
    height: 40rem;
  }

  .badge.badge-secondary {
    font-size: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.75rem;
  }
}
</style>
