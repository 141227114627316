<template>
  <PurchaseConfirmation
      document-name="Агентское распоряжение на приобретение прав требования с обязательством обратного выкупа(скачать PDF)"
      seller-document-name="Оферта о заключении договора уступки прав требований (цессии) с обязательством обратного выкупа"
  />
</template>
<script>
import PurchaseConfirmation from "./PurchaseConfirmation";
export default {
  components: {PurchaseConfirmation}
}
</script>