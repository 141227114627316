export enum DashboardEventType {
  ProjectRequestCreated,
  ProjectPublished,
  Invested,
  InvestmentCanceled,
  Purchased,
  WithdrawRequested,
  WithdrawCanceled,
  RulesConfirmed
}
